import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45f4abba"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "img" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        span: 24,
        style: {"margin":"0 10px","font-size":"20px"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 设备插件 "),
          _createVNode(_component_a_input_search, {
            value: _ctx.dpQueryName,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dpQueryName) = $event)),
            placeholder: "搜索",
            style: {"width":"200px","float":"right","margin-right":"20px"},
            onSearch: _cache[1] || (_cache[1] = ()=>{_ctx.searchDevPlugin()})
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_divider),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devList, (item, index) => {
        return (_openBlock(), _createBlock(_component_a_col, {
          key: index,
          class: "pluginBox"
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.toAdd('addDev',item))
            }, [
              _createElementVNode("div", {
                class: "label",
                title: item.Title
              }, _toDisplayString(item.Title), 9, _hoisted_2),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.imageUrl+item.Icon,
                  width: "80"
                }, null, 8, _hoisted_4)
              ])
            ], 8, _hoisted_1)
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createVNode(_component_a_col, {
        span: 24,
        style: {"margin":"20px 10px 0 10px","font-size":"20px"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 服务插件 "),
          _createVNode(_component_a_input_search, {
            value: _ctx.spQueryName,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.spQueryName) = $event)),
            placeholder: "搜索",
            style: {"width":"200px","float":"right","margin-right":"20px"},
            onSearch: _cache[3] || (_cache[3] = ()=>{_ctx.searchServicePlugin()})
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_divider),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceList, (item, index) => {
        return (_openBlock(), _createBlock(_component_a_col, {
          key: index,
          class: "pluginBox"
        }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.toAdd('addService',item))
            }, [
              _createElementVNode("div", {
                class: "label",
                title: item.Title
              }, _toDisplayString(item.Title), 9, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: _ctx.imageUrl+item.Icon,
                  width: "80"
                }, null, 8, _hoisted_8)
              ])
            ], 8, _hoisted_5)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}