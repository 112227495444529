
import {defineComponent, onMounted, ref} from "vue";
import {getServicePlugin, getDevPlugin, getDevConfigSchema, getServiceConfigSchema} from "@/api/api_x2server";
import {useStore} from "vuex";
import {useRouter} from 'vue-router'

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter()
    const imageUrl = ref<string>(sessionStorage.getItem("Host") + "/File/GetImage?image=");
    const devList = ref<any>();
    const serviceList = ref<any>();
    const params = ref<any>();
    const dpQueryName = ref<string>();
    const spQueryName = ref<string>();

    let all_devList: any = []
    let all_serviceList: any = []

    onMounted(async () => {
      const res = await getDevPlugin();
      all_devList = res;
      devList.value = res;
      const res2 = await getServicePlugin();
      all_serviceList = res2;
      serviceList.value = res2;
    })

    const toAdd = async (type: string, item: any) => {
      let res: any;
      let basicItems: any;
      if (type === "addDev") {
        res = await getDevConfigSchema({type: item.Name})
        basicItems = [{
          IsReq: true,
          Key: "Name",
          T: "String",
          Title: "设备名",
          V: ""
        }, {
          IsReq: false,
          Key: "Code",
          T: "String",
          Title: "设备编码",
          V: ""
        }, {
          IsReq: false,
          Key: "Des",
          T: "String",
          Title: "设备描述",
          V: ""
        }]
      } else if (type === "addService") {
        res = await getServiceConfigSchema({type: item.Name})
        basicItems = [{
          IsReq: true,
          Key: "Name",
          T: "String",
          Title: "服务名",
          V: ""
        }, {
          IsReq: false,
          Key: "Des",
          T: "String",
          Title: "服务描述",
          V: ""
        }]
      }
      if (res.Schema.Items) {
        res.Schema.Items = basicItems.concat(res.Schema?.Items)
      } else {
        res.Schema.Items = basicItems
      }

      params.value = {
        title: item.Title,
        icon: imageUrl.value + item.Icon,
        introduce:res.Schema.Des,
        configSchema: res
      }
      store.commit("setConfigParams", params);
      router.push({
        name: "configurationView", params: {
          type,
          configType: item.Name
        }
      })
    }

    const searchDevPlugin = () => {
      devList.value = all_devList.filter((e: any)=> e.Title.indexOf(dpQueryName.value) != -1)
    }
    const searchServicePlugin = () => {
      serviceList.value = all_serviceList.filter((e: any)=> e.Title.indexOf(spQueryName.value) != -1)
    }

    return {
      devList,
      serviceList,
      imageUrl,
      params,
      dpQueryName,
      spQueryName,
      searchDevPlugin,
      searchServicePlugin,
      toAdd,
    }
  }
})
