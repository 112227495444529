
import Editor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import {defineComponent, ref, onMounted} from "vue";
import {GetPluginMaterial, GetScriptMaterial} from "@/api/api_x2server";

export default defineComponent({
  components: {
    Editor
  },
  setup(){
    onMounted(()=>{
      init()
    })
    const source = ref<string>("")
    const init = async () => {
      let res: any = await GetPluginMaterial();
      source.value = res;
    }
    return{
      source,
    }
  }
})
