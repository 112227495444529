
import {defineComponent, ref} from "vue";
import installed from "./modules/installed.vue"
import store from "./modules/store.vue"
import addNewPluginDocs from "@/views/plugin/modules/addNewPluginDocs.vue";

export default defineComponent({
  components:{installed,store,addNewPluginDocs},
  setup() {
    return {
      activeKey: ref<string>("1"),
    }
  }
})
