
import {defineComponent, onMounted, ref, createVNode} from "vue";
import {
  addPluginOrder,
  getStoreDevList, getStoreServiceList, installPlugin
} from "@/api/api_x2server";
import {Modal, message} from 'ant-design-vue';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
  setup() {
    const imageUrl = ref<string>(sessionStorage.getItem("Host") + "/Plugin/GetPluginIcon?id=");
    const devList = ref<any>();
    const serviceList = ref<any>();
    const params = ref<any>();
    const dpQueryName = ref<string>();
    const spQueryName = ref<string>();
    const freeStatus = ref<boolean>(false);
    const visible = ref<boolean>(false);
    const selectItem = ref<any>();

    let all_devList: any = []
    let all_serviceList: any = []

    onMounted(() => {
      init()
    })

    const init = async () => {
      const res: any = await getStoreDevList({
        isFree: freeStatus.value,
        pos: 0,
        count: 999
      });
      all_devList = res.Items;
      devList.value = res.Items;
      const res2: any = await getStoreServiceList({
        isFree: freeStatus.value,
        pos: 0,
        count: 999
      });
      all_serviceList = res2.Items;
      serviceList.value = res2.Items;
    }

    const searchDevPlugin = () => {
      devList.value = all_devList.filter((e: any) => e.Name.indexOf(dpQueryName.value) != -1)
    }
    const searchServicePlugin = () => {
      serviceList.value = all_serviceList.filter((e: any) => e.Name.indexOf(spQueryName.value) != -1)
    }

    const toBuy = (item: any) => {
      visible.value = true
      selectItem.value = item
    }
    const toInstall = async (item: any) => {
      Modal.confirm({
        title: () => '是否安装该插件?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await installPlugin({
            id: item.Id,
            Type: item.Type,
            Name: item.Name,
          })

          if (res.success == true) {
            init();
            message.success("安装成功！")
          }
        },
      });
    }
    const addOrder = async () => {
      const res: any = await addPluginOrder({id: selectItem.value.Id})

      if (res.success == true) {
        init();
        visible.value = false
        message.success("购买成功！")
      }
    }

    return {
      freeStatus,
      devList,
      serviceList,
      imageUrl,
      params,
      dpQueryName,
      spQueryName,
      visible,
      selectItem,
      searchDevPlugin,
      searchServicePlugin,
      init,
      toBuy,
      toInstall,
      addOrder,
    }
  }
})
