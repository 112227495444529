import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_installed = _resolveComponent("installed")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_store = _resolveComponent("store")!
  const _component_addNewPluginDocs = _resolveComponent("addNewPluginDocs")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createBlock(_component_a_tabs, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tab_pane, {
        key: "1",
        tab: "已安装"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_installed)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "2",
        tab: "插件商店"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_store)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "3",
        tab: "开发新插件"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_addNewPluginDocs)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey"]))
}